import {addressFields} from "./AddressMixin";
import {} from "vue";

const FileMixin = {
    methods: {
        CSVToArray(strData, strDelimiter = null) {
            strDelimiter = (strDelimiter || ",");

            //sostituisco qualsiasi ',' con '.'
            if (strDelimiter !== ","){
                strData = strData.replaceAll(",", ".");
            }

            const objPattern = new RegExp(
                (
                    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                    "([^\"\\" + strDelimiter + "\\r\\n]*))"
                ),
                "gi"
            );
            let arrData = [[]];
            let arrMatches = null;
            while ((arrMatches = objPattern.exec(strData))) {
                let strMatchedDelimiter = arrMatches[1];
                if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                    arrData.push([]);
                }
                let strMatchedValue;
                if (arrMatches[2]) {
                    strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"");
                } else {
                    strMatchedValue = arrMatches[3];
                }
                arrData[arrData.length - 1].push(strMatchedValue);
            }

            return (arrData);
        },
        CSVToObj(csv, sep) {
            let csvArray = this.CSVToArray(csv, sep).filter(row => !row.every((el) => el===""));
            //const lines = csv.split('\r\n')
            const result = []
            //const headers = lines[0].split(',')
            const headers = csvArray.shift().filter(el => el!=="");

            csvArray.forEach(row => {
                let obj = {}
                let i = 0;
                headers.forEach(field => {
                    if (addressFields.indexOf(field)>=0){
                        if (!obj["address"]){
                            obj["address"]={};
                        }
                        obj["address"][field]=row[i++];
                    }
                    else{
                        obj[field]=row[i++];
                    }
                });
                result.push(obj);
            })
            return result;
        },
        async readTextFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async readIMGFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
    }
}

export default FileMixin
